import React from 'react';
import { Link } from "react-router-dom";
import '../services/Services.css';
import hightlightImg from '../assets/highlightText.png';

const Services = ({ val, screenType }) => {
  const serviceBoxData = [
    {
      id: 1,
      title: "Mobile App Development",
      description: 'Our team of developers can help you bring your app concept to life. With our extensive experience in mobile app development, we understand the intricacies and can help you create an app that meets all your expectations. We use the latest technologies to ensure that the app is secure, efficient, and user- friendly.Our developers are passionate about creating innovative and engaging apps that will help you reach your goals.With our expertise, you can be sure that your project will be delivered on time and with great quality.',
      imgsrc: require('../assets/mobile-app-icon.png'),
      nextimg: require('../assets/arrow-right.png')
    },
    {
      id: 2,
      title: "Web App Development",
      description: 'We are pleased to offer comprehensive web development services tailored to your exact needs. Our solutions are designed to be both aesthetically pleasing and highly effective, providing you with a powerful web presence. Our team of experienced developers is committed to creating a website that meets your specifications and surpasses your expectations. Drawing on our expertise, your website will become a valuable asset for your business.',
      imgsrc: require('../assets/web-app-icon.png'),
      nextimg: require('../assets/arrow-right.png')
    },
    {
      id: 3,
      title: "UI/UX Designing",
      description: 'We are delighted to offer our professional UI/UX design services to our esteemed clients. Our solutions ensure the best user experience, allowing your product to stand out from the competition. Our experienced UI/UX designers have an in-depth knowledge of user interface design principles and will develop a visually attractive and user-friendly interface. With our expertise, you can be confident that your product will be crafted with your user in mind and will be highly engaging.',
      imgsrc: require('../assets/ui-ux-icon.png'),
      nextimg: require('../assets/arrow-right.png')
    },
    {
      id: 4,
      title: "QA/Testing",
      description: 'We are delighted to offer comprehensive Quality Assurance and Testing services to our valued clients. Our expert team of testers will ensure that your product is thoroughly tested and any possible defects are identified prior to release. We employ the most advanced testing techniques and technologies to guarantee that your product is prepared for market. We guarantee you the highest standards of testability and quality assurance for your product to ensure the highest quality output.',
      imgsrc: require('../assets/qa-testing-icon.png'),
      nextimg: require('../assets/arrow-right.png')
    },
    {
      id: 5,
      title: "IT Consultation",
      description: 'We are offering IT Consultation services to help companies improve their IT infrastructure and processes. Our team of experienced consultants will provide the guidance and support necessary to ensure your IT systems are as efficient and effective as possible. We will analyze your current environment and provide tailored recommendations to help you get the most out of your technology investments. Whether you are looking to upgrade hardware, migrate to the cloud, or just optimize your existing environment, our IT Consultation services are here to help.',
      imgsrc: require('../assets/it-consulting-icon.png'),
      nextimg: require('../assets/arrow-right.png')
    },
    {
      id: 6,
      title: "Social Media Marketing",
      description: 'Revolutionize your online presence with our cutting-edge Social Media Marketing service. Our team of experts will craft dynamic, engaging content tailored to your brand, driving traffic, increasing engagement, and boosting your overall online visibility. Stay ahead of the competition and reach new heights of success with our top-notch Social Media Marketing strategy. Elevate your brand to new heights with our comprehensive and targeted approach. Let us help you make an impact in the digital world!',
      imgsrc: require('../assets/social-media.png'),
      nextimg: require('../assets/arrow-right.png')
    },
  ];

  return (
    <div class='serviceMain my-5'>
      <div class='container'>
        <div class='service_banner text-center mb-5'>
          <h1>What
            <span>
              services
              <img src={hightlightImg} alt="Description of the image" />
            </span>
            we are offering?</h1>
        </div>

        {
          screenType == 'Home' ?
            <div class="row">
              {serviceBoxData.map((val) => (
                <div key={val.id} className="col-lg-4 col-md-6 col-sm-12 mt-3">
                  <div className="serviceBox mt-3 p-3">
                    <img class='btn-primary-main swing' src={val.imgsrc} />
                    <div class='serviceBoxInner mt-5'>
                      <p>{val.title}</p>
                      <span class='clamped-text'>{val.description}</span>
                      <Link to="/service" class='ms-auto'>
                        <img className="seeMoreIcon" src={val.nextimg} alt="Service Icon" />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div> :
            <div class="row">
              {serviceBoxData.map((val) => (
                <div key={val.id} className="col-lg-12 col-md-6 col-sm-12 mt-3">
                  <div className="serviceBox mt-3 p-3">
                    <img class='btn-primary-main swing' src={val.imgsrc} />
                    <div class='serviceBoxInner mt-5'>
                      <p>{val.title}</p>
                      <span>{val.description}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
        }
      </div>
    </div>
  )
}


// {ServiceData.map((val) => {
//   return (
//     <div className="col-lg-3 col-md-6 col-sm-12">
//       <div className="serviceBox mt-5">
//         title= {val.title}
//       </div>
//     </div>
//   );
// })}

export default Services