import React from 'react';
import { Link } from "react-router-dom";
import '../technology/Technology.css';
import backgroundImage from '../assets/tech_bg.png';
import hightlightImg from '../assets/highlightText.png';

const Technology = ({ screenType }) => {
  const techBoxData = [
    {
      id: 1,
      title: 'React Native',
      description: 'We are excited to offer React Native development services to bring your mobile app ideas to life. React Native enables developers to create cross-platform mobile applications for Android and iOS using JavaScript, enabling them to build feature-rich, user-friendly apps without compromising on performance or quality. Our skilled team of React Native developers is ready to assist you in making the most of your project. Reach out to us today to get started!',
      imgsrc: require('../assets/react-native-icon.png'),
      nextimg: require('../assets/arrow-right.png')
    },
    {
      id: 2,
      title: 'Flutter',
      description: 'Flutter is an advanced mobile app development platform developed by Google, offering developers an intuitive and comprehensive toolkit for creating beautiful, performant, and secure apps for both Android and iOS. It is built on a modern, reactive framework with a vast library of widgets, enabling developers to create visually stunning and highly efficient mobile applications that are secure, fast, and reliable. With Flutter, developers can create apps that are powerful, secure, and aesthetically pleasing, all without sacrificing performance or security.',
      imgsrc: require('../assets/flutter-icon.png'),
      nextimg: require('../assets/arrow-right.png')
    },
    {
      id: 3,
      title: 'Javascript',
      description: 'Javascript is one of the most popular programming languages used today. It is a powerful and versatile language that is used to create dynamic websites, applications, and interactive web experiences. With its versatility and ease of use, Javascript has become a staple of web development, allowing developers to create webpages and applications quickly and easily. Javascript is also used in mobile and desktop applications, making it a great choice for creating dynamic and interactive apps.',
      imgsrc: require('../assets/java-script.png'),
      nextimg: require('../assets/arrow-right.png')
    },
    {
      id: 4,
      title: 'Figma',
      description: 'Figma is a powerful and intuitive design tool that enables teams to collaborate on the same design in real time. It offers an extensive set of features for creating, editing, and sharing designs, including vector tools, prototyping, and integration with other tools. With Figma, teams can work together to create high-quality designs quickly and efficiently. Its an essential tool for any team looking to collaborate on projects and create impactful, user-friendly designs.',
      imgsrc: require('../assets/figma.png'),
      nextimg: require('../assets/arrow-right.png')
    },
    {
      id: 5,
      title: 'Firebase',
      description: 'We are using Firebase to power our application. Firebase is a powerful mobile and web application development platform that provides a wide range of services, such as authentication, real-time databases, hosting, storage, and many more. It is a great choice for quickly building applications that require fast and reliable performance. Firebase also provides advanced features such as analytics and machine learning capabilities to help make our application more powerful and easier to use.',
      imgsrc: require('../assets/firebase.png'),
      nextimg: require('../assets/arrow-right.png')
    },
    {
      id: 6,
      title: 'Node JS',
      description: 'In our professional endeavors, Node.js has proven to be a pivotal technology that revolutionizes the way we develop and deploy applications. As a server-side JavaScript runtime environment, it allows us to build scalable, high-performance web applications with ease. By leveraging its event-driven architecture and asynchronous programming model, we are able to handle multiple requests simultaneously without compromising speed or efficiency. Additionally, its rich ecosystem of modules and packages enables us to seamlessly integrate various functionalities into our applications, speeding up development time and enhancing overall functionality.',
      imgsrc: require('../assets/nodejs.png'),
      nextimg: require('../assets/arrow-right.png')
    },
    {
      id: 7,
      title: 'Mongo DB',
      description: 'In our organization, we have implemented MongoDB technology as a part of our data management strategy. MongoDB is an open-source NoSQL database that offers high performance, scalability, and flexibility for handling large volumes of data. The document-oriented structure of MongoDB allows us to store and retrieve unstructured and semi-structured data efficiently, making it ideal for various use cases such as content management systems, real-time analytics, and mobile applications. Additionally, the distributed architecture of MongoDB enables us to scale horizontally by adding more nodes to the cluster without compromising on performance.',
      imgsrc: require('../assets/database.png'),
      nextimg: require('../assets/arrow-right.png')
    },
    {
      id: 8,
      title: 'Dot Net (C#)',
      description: 'Looking to take your business to the next level with cutting-edge technology? Our team specializes in utilizing .NET technology to create dynamic and innovative solutions for your company. From website development to custom software applications, we have the expertise to bring your vision to life. With .NET technology, the possibilities are endless. Upgrade your business today and stay ahead of the competition with our top-notch services.',
      imgsrc: require('../assets/web.png'),
      nextimg: require('../assets/arrow-right.png')
    },
  ];
  return (
    <div class='technologyMain my-5'>
      <div class='container'>
        <div class='technology_banner text-center mb-5 pt-5'>
          <h1>
            <span>
              Technology
              <img src={hightlightImg} alt="Description of the image" />
            </span>
            we used</h1>
        </div>

        {
          screenType == 'Home' ?
            <div class="row">
              {techBoxData?.slice(0, 6)?.map((val) => (
                <div key={val.id} className="col-lg-4 col-md-6 col-sm-12">
                  <div className="technologyBox mt-5 p-3" style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <img class='btn-primary-main swing' src={val.imgsrc} />
                    <div class='technologyBoxInner'>
                      <p>{val.title}</p>
                      <span class='clamped-text'>{val.description}</span>
                      <Link to="/technology" class='ms-auto d-flex'>
                        <img className="seeMoreIcon me-3" src={val.nextimg} alt="Service Icon" />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div> :
            <div class="row">
              {techBoxData?.map((val) => (
                <div key={val.id} className="col-lg-6 col-md-6 col-sm-12">
                  <div className="technologyBox technologyBoxMain mt-5 p-3" style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <img class='btn-primary-main swing' src={val.imgsrc} />
                    <div class='technologyBoxInner'>
                      <p>{val.title}</p>
                      <span>{val.description}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
        }
      </div>
    </div>
  )
}

export default Technology