import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './components/navbar/Navbar';
import About from './components/about/About';
import Home from './components/home/Home';
import Services from './components/services/Services';
import Projects from './components/projects/Projects';
import Technology from './components/technology/Technology';
import Footer from './components/footer/Footer';
import BottomToTop from './components/bottomToTop/BottomToTop';
import Privacypolicy from './components/privacyPolicy/Privacypolicy';
import Lifeours from './components/lifeOurs/lifeours';
import ChatBot from './components/chatbot/Chatbot';
import CircleCursor from './components/circleCursor/CircleCursor';
import Jobs from './components/jobs/Jobs';
import ClickOnTop from './components/innerComponents/ClickOnTop';

const App = () => {
  const scrollToBottomRef = useRef(null);
  return (
    <Router>
      <Navbar onScrollToBottom={() => scrollToBottomRef.current()} />
      <ClickOnTop />
      <Routes>
        <Route exact path="/" element={<Home setScrollToBottom={scrollToBottomRef} />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/service" element={<Services />} />
        <Route exact path="/project" element={<Projects />} />
        <Route exact path="/technology" element={<Technology />} />
        <Route exact path="/Privacypolicy" element={<Privacypolicy />} />
        <Route exact path="/lifeours" element={<Lifeours />} />
        <Route exact path="/jobs" element={<Jobs />} />
      </Routes>
      <Footer />
      <BottomToTop />
      <CircleCursor />
      {/* <ChatBot /> */}
    </Router>
  );
};

export default App;
