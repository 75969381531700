import React from 'react';
import hightlightImg from '../assets/highlightText.png';
import '../lifeOurs/lifeours.css';
import WhyChooseUs from '../innerComponents/WhyChooseUs';
import chooseImg from '../assets/why-choose-us.jpg';
import WaveEffect from '../innerComponents/WaveEffect';

const lifeours = () => {
    const chooseData = [
        {
            id: 1,
            title: 'Innovative Projects',
            description: 'Be a part of groundbreaking projects that push the boundaries of technology. From developing custom applications to working with AI and cloud services, our team is leading the way in innovation.',
            chooseImg: require('../assets/innovation.png')
        },
        {
            id: 2,
            title: 'Career Growth Opportunities',
            description: 'Your professional development is our top priority. With access to continuous learning opportunities, mentorship programs, and challenging projects, you will have the support you need to excel and advance in your career.',
            chooseImg: require('../assets/Career--Growth-Opportunities.png')
        },
        {
            id: 3,
            title: 'Collaborative Culture',
            description: 'Join a team that values collaboration, creativity, and diversity. Our inclusive environment ensures that every voice is heard and every idea is valued.',
            chooseImg: require('../assets/Collaborative-Culture.png')
        },
        {
            id: 4,
            title: 'Focus on Well-being',
            description: 'We care about your well-being. Enjoy flexible work hours, comprehensive health and wellness programs, and a supportive atmosphere that promotes a healthy work-life balance.',
            chooseImg: require('../assets/Focus-on-Well-being.png')
        },
        {
            id: 5,
            title: 'Cutting-Edge Technology',
            description: 'Stay ahead of the curve by working with the latest technologies and tools. At GrowMore Wiserly, you will have the opportunity to continuously learn and apply new tech, keeping you at the forefront of the industry.',
            chooseImg: require('../assets/Cutting-Edge-Technology.png')
        },
        {
            id: 6,
            title: 'Remote Work Options',
            description: 'We support our employees in working in the most productive environment for them, allowing them to work from their preferred locations. Join us at GrowMore Wiserly and take your career to new heights!',
            chooseImg: require('../assets/Remote-Work-Options.png')
        },
    ];
    return (
        <div className='lifeoursContent'>
            <div className='container-fluid'>
                <WaveEffect />
            </div>
            <div className='container'>
                {/* <h4>Life@ GrowMore Wiserly</h4>
                <p>Are you an IT professional seeking a career change or exploring new job
                    opportunities? If so, GrowMore Wiserly could be the ideal fit for you. As a
                    software engineer, you recognize the significance of working for a company that
                    prioritizes innovation, teamwork, and personal development. At GrowMore, we
                    embody these values and offer a nurturing environment where you can excel both
                    personally and professionally.</p> */}

                <div class='blog_banner text-center my-5'>
                    <h1>
                        <span>
                            Why join our team
                            <img src={hightlightImg} alt="Description of the image" />
                        </span>
                    </h1>
                </div>

                <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <div className='team_image'>
                            <img src={chooseImg} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='chooseUsDesc'>
                            <p>Life at GrowMore is truly unique; our team comprises skilled individuals who are
                                deeply passionate about their work. As a software engineer, you will engage in
                                cutting-edge projects, collaborate with like-minded peers, and continuously
                                enhance your skill set. Whether you are an experienced professional or just
                                starting out, GrowMore provides a supportive and inclusive atmosphere where
                                you can realize your full potential.
                                One of the standout features of working at GrowMore is our commitment to
                                ongoing learning. As a software engineer, you will have access to workshops,
                                training sessions, and various resources to keep you ahead in this rapidly
                                evolving industry. Our aim is to help you grow wisely and become the best
                                version of yourself.</p>
                            <p>However, life at GrowMore is not all work and no play. Our office culture is vibrant
                                and dynamic, featuring team-building activities, social events, and occasional
                                hackathons. We believe that a happy and well-rounded team is a successful
                                team, and we strive to foster an environment where everyone feels valued and
                                supported.
                                If you are ready to elevate your career and join a company that genuinely values
                                its employees, consider GrowMore. Life here is about more than just work; it's
                                about growth, collaboration, and endless possibilities. Come be a part of our
                                team and grow wisely with us!</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid'>
                <div class='blog_banner text-center my-5'>
                    <h1>
                        <span>
                            Perks & Benefits
                            <img src={hightlightImg} alt="Description of the image" />
                        </span>
                    </h1>
                </div>

                <WhyChooseUs aboutData={chooseData} columnClass='col-lg-4 col-md-6 col-sm-12' />
            </div>
        </div >
    )
}

export default lifeours