import React from 'react';
import imgsrc from '../assets/Group 33915.png';
import '../achivement/Achivement.css';

const Achivement = () => {
    const achiveBoxData = [
        {
            id: 1,
            title: '10',
            description: 'Year of experience',
        },
        {
            id: 2,
            title: '20',
            description: 'Projects are delivered successfully',
        },
        {
            id: 3,
            title: '24*7',
            description: 'Providing 24*7 support',
        },
    ];
    return (
        <>
            <div class='achiveMain mt-5'>
                <div class='container'>
                    <div class="row">
                        {achiveBoxData.map((val) => (
                            <div key={val.id} className="col-lg-4 col-md-6 col-sm-12">
                                <div className="achiveBox">
                                    <p>{val.title}</p>
                                    <span>{val.description}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div class='achiveImg'>
                <img src={imgsrc} />
            </div>
        </>
    )
}

export default Achivement