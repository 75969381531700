import React, { useEffect } from "react";

const GlobalEventHandler = () => {
  useEffect(() => {
    const handleCopy = (e) => {
      e.preventDefault();
      alert("Don't be copy paste, Write it youself!");
    };
    document.body.addEventListener("copy", handleCopy);
    return () => {
      document.body.removeEventListener("copy", handleCopy);
    };
  }, []);

  return null;
};
export default GlobalEventHandler;