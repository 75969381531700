import React from 'react';
import '../testimonials/Testimonial.css';
import backgroundImage from '../assets/Group-33906.png';
import hightlightImg from '../assets/highlightText.png';
import bannerImg from '../assets/proj-img1.png';
import bannerImg1 from '../assets/client1.jpeg';
import bannerImg2 from '../assets/client2.jpeg';

const Testimonial = () => {
    return (
        <div class='testimonialMain' style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div class='container'>
                <div class='testimonial_banner text-center'>
                    <h1>
                        <span>
                            Testimonial
                            <img src={hightlightImg} alt="Description of the image" />
                        </span>
                    </h1>
                </div>

                <div id="carouselExampleFade" class="carousel slide carousel-fade mt-5" data-bs-ride="carousel" data-bs-interval="10000">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class='clientDetail'>
                                <div class='clientavtarFrame'>
                                    <img src={bannerImg2} class="d-block" alt="..." />
                                </div>
                                <div class='clientFeedback'>
                                    <h4>Manish Tanwar</h4>
                                    <p>Founder of WealthWizardsBkn</p>
                                    <span>We can't express enough how grateful we are for the exceptional service and
                                        support received as a client from “GrowMore Wiserly Team”. From start to
                                        finish, every interaction was handled with professionalism and care. I highly
                                        recommend their services to anyone looking for top-notch customer service
                                        and a genuine commitment to client satisfaction. Thank you for making me
                                        feel like a valued customer!</span>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class='clientDetail'>
                                <div class='clientavtarFrame'>
                                    <img src={bannerImg1} class="d-block" alt="..." />
                                </div>
                                <div class='clientFeedback'>
                                    <h4>Anand Agarwal</h4>
                                    <p>Head of The Real Estate Team</p>
                                    <span>Growmorewiserly boasts an exceptional and experienced development team,
                                        providing amazing digital solutions for businesses. their help support team are
                                        also top-notch, making for a truly pleasant and rewarding experience when
                                        working with them.
                                    </span>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>  

            </div>
        </div>
    )
}

export default Testimonial