import React from 'react';
import { Link } from "react-router-dom";
import '../weWorkWith/WeWorkWith.css';
import hightlightImg from '../assets/highlightText.png';

const WeWorkWith = (screenType) => {
    const workWithData = [
        {
            id: 1,
            title: "Real Estate",
            imgsrc: require('../assets/real-estate.png'),
        },
        {
            id: 2,
            title: "Education",
            imgsrc: require('../assets/education.png'),
        },
        {
            id: 3,
            title: "Ecommerce",
            imgsrc: require('../assets/e-commerce.png'),
        },
        {
            id: 4,
            title: "Games",
            imgsrc: require('../assets/games.png'),
        },
        {
            id: 5,
            title: "HealthCare",
            imgsrc: require('../assets/healthcare.png'),
        },
        {
            id: 6,
            title: "On-Demand",
            imgsrc: require('../assets/on-demand.png'),
        },
        {
            id: 7,
            title: "Grocery",
            imgsrc: require('../assets/grocery.png'),
        },
        {
            id: 8,
            title: "Fitness-Center",
            imgsrc: require('../assets/fitness.png'),
        },
    ];
    return (
        <div class='workwithMain mt-5'>
            <div class='container'>
                <div class='workwith_banner text-center mb-5 pt-5'>
                    <h1>Industries We
                        <span>
                            Work
                            <img src={hightlightImg} alt="Description of the image" />
                        </span>
                        With</h1>
                </div>

                <div class="row">
                    {workWithData.map((val) => (
                        <div key={val.id} className="col-lg-3 col-md-6 col-sm-6 mt-3">
                            <div className="serviceBox weworkwith text-center mt-3 p-3">
                                <img class='btn-primary-main swing' src={val.imgsrc} />
                                <div class='workWithInner mt-4'>
                                    <p class='mb-0'>{val.title}</p>
                                    <span class='clamped-text'>{val.description}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default WeWorkWith