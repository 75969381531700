import React from 'react';
import { Link } from "react-router-dom";
import '../blogs/Blogs.css';
import hightlightImg from '../assets/highlightText.png';
import blogBg from '../assets/blog-bg.png';

const Blogs = () => {
    const blogData =
        [
            {
                id: 1,
                title: 'React Native’s New Architecture Explore and Features',
                description: 'In the fast moving world, it’s important to upgrade everything in your lives. In the rising lifestyle, technology has also been upgraded rapidly. The cross-platform mobile applications went through drastic change in 2024–2025. Isn’t it fascinating how cross-platform mobile applications in 2024 are easy-in the lives of developers’ community? React Native is one such technology which has turn-the tables affirmatively in the tech-world.',
                blogImg: require('../assets/blog-post1.png'),
                nextimg: require('../assets/arrow-right.png')
            },
            {
                id: 2,
                title: 'Top Features of the New Android OS 15: Find Out Introduction',
                description: 'Android OS has consistently led the mobile operating system market, with over 2.5 billion active devices worldwide. The recent release of Android OS 15 marks another leap forward, bringing significant enhancements that cater to user needs and security. This update not only reflects the evolving mobile landscape but also emphasizes Google’s commitment to providing a safer and more enjoyable user experience. This article explores the standout features that make Android OS 15 compelling, from advanced security to an improved user interface.',
                blogImg: require('../assets/blog-post2.png'),
                nextimg: require('../assets/arrow-right.png')
            },
            {
                id: 3,
                title: 'Best Practices for Putting DevOps into Action in Your Organization',
                description: 'In today’s fast-paced tech world, companies need to build and roll out software solutions quicker than before. DevOps — which combines “development” and “operations” — has become the go-to method to achieve this. By closing the gap between development and IT operations, DevOps influences teamwork. It helps to streamline your workflow and make sure you’re always delivering top-notch software.',
                blogImg: require('../assets/devops-img.jpeg'),
                nextimg: require('../assets/arrow-right.png')
            },
        ]
    return (
        <div class='blogMain'>
            <div class='container'>
                <div class='blog_banner text-center mt-5'>
                    <h1>
                        <span>
                            Latest Blogs
                            <img src={hightlightImg} alt="Description of the image" />
                        </span>
                    </h1>
                </div>

                {/* <div class='blogBg'>
                    <img class='w-100' src={blogBg} alt="Blog Bg" />
                </div> */}

                <div class='row'>
                    {blogData.map((val) =>
                        <div key={val.id} class='col-lg-4 col-md-6 col-sm-12'>
                            <div class='bloginner mt-5'>
                                <div class='blogImges'>
                                    <div class='blogDate d-none'>
                                        <p>23</p>
                                        <span>August</span>
                                    </div>
                                    <img src={val.blogImg} style={{ width: '100%' }} />
                                </div>
                                <div class='blogInnerData'>
                                    <p class='mt-4'>{val.title}</p>
                                    <span class='clamped-text'>{val.description}</span>
                                </div>
                                <Link to="https://medium.com/@growmorewiserly" target="_blank" class='ms-auto d-flex'>
                                    <img className="seeMoreIcon" src={val.nextimg} alt="Blog Icon" />
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Blogs