import React from 'react';
import '../projects/Projects.css';
import hightlightImg from '../assets/highlightText.png';
import backgroundImage from '../assets/tech_bg.png';
import { Link } from 'react-router-dom';

const Projects = ({ screenType }) => {
  const projBoxData = [
    {
      id: 1,
      title: 'The Real Estate Jaipur',
      description: ' A comprehensive real estate application that enables users to explore properties based on their current location.The platform provides a seamless experience from user registration to property discovery, enhancing engagement through interactive features like messaging, real- time notifications, and personalized profiles.Built with modern technologies, the application delivers fast, scalable, and user - friendly services.',
      imgsrc: require('../assets/proj-img1.png'),
    },
    {
      id: 2,
      title: 'WealthWizardsBkn',
      description: 'An intuitive platform designed to facilitate seamless communication between users and agents for financial and investment services. The application simplifies user onboarding, enables secure authentication, and supports personalized service requests. With multilingual support and real-time notifications, it ensures accessibility and timely updates for users.',
      imgsrc: require('../assets/proj-img-2.png'),
    },
  ];

  const projBoxDataInner = [
    {
      id: 1,
      title: "The Real Estate Jaipur",
      description:
        "A comprehensive real estate application that enables users to explore properties based on their current location. The platform provides a seamless experience from user registration to property discovery, enhancing engagement through interactive features like messaging, real-time notifications, and personalized profiles. Built with modern technologies, the application delivers fast, scalable, and user-friendly services.",
      keyfeature: "Key Features",
      techfeature: 'Tech Stack',
      imgsrc: require("../assets/proj-img1.png"),
      features: [
        {
          title: "User Registration & Authentication",
          details: [
            "Email and mobile number registration.",
            "OTP verification for secure account creation.",
            "Login via mobile number or social accounts (Google/Facebook).",
          ],
        },
        {
          title: "Location-Based Property Listings",
          details: [
            "Auto-detect current location.",
            "Display relevant property listings based on the user's area.",
          ],
        },
        {
          title: "Dynamic Home Page",
          details: [
            "Interactive banners and sliders showcasing featured properties or offers.",
          ],
        },
        {
          title: "Property Details Page",
          details: [
            "Detailed property information with high-quality images.",
            "Key details like price, size, location, and amenities.",
          ],
        },
        {
          title: "Messaging Functionality",
          details: [
            "In-app chat feature for direct communication with property owners or agents.",
          ],
        },
        {
          title: "Push Notifications",
          details: [
            "Timely updates on property availability, offers, or user messages.",
          ],
        },
        {
          title: "Profile Management",
          details: [
            "Edit and update user profile information, including preferences.",
          ],
        },
        {
          title: "Sort & Filter Functionality",
          details: [
            "Advanced filters based on price, location, property type, and more.",
            "Sorting options for an optimized search experience.",
          ],
        },
      ],
      techStack: [
        {
          category: "Frontend",
          tools: [
            "HTML, CSS, JavaScript: For crafting responsive and interactive web pages.",
            "React: Building dynamic and reusable UI components for the web.",
            "React Native: Creating a cross-platform mobile application for Android and iOS.",
          ],
        },
        {
          category: "Backend",
          tools: [
            "Node.js: For building scalable and high-performance server-side logic.",
          ],
        },
        {
          category: "Database",
          tools: [
            "MongoDB: A NoSQL database for efficient storage and retrieval of property and user data.",
          ],
        },
        {
          category: "State Management",
          tools: [
            "Redux: Managing the application’s state and improving scalability.",
          ],
        },
        {
          category: "Authentication & Notifications",
          tools: [
            "Firebase: Managing user authentication and push notifications.",
          ],
        },
        {
          category: "Hosting & APIs",
          tools: [
            "Firebase APIs for seamless integration and hosting scalability.",
          ],
        },
      ],
    },
    {
      id: 2,
      title: " WealthWizardsBkn",
      description:
        " An intuitive platform designed to facilitate seamless communication between users and agents for financial and investment services. The application simplifies user onboarding, enables secure authentication, and supports personalized service requests. With multilingual support and real-time notifications, it ensures accessibility and timely updates for users.",
      keyfeature: "Key Features",
      techfeature: 'Tech Stack',
      imgsrc: require("../assets/proj-img1.png"),
      features: [
        {
          title: "User Registration & Authentication",
          details: [
            "Email and mobile number registration with OTP verification for enhanced security.",
            "Login options via mobile number and social accounts (Google/Facebook).",
            "Biometric authentication for a secure and hassle-free login experience on mobile apps.",
          ],
        },
        {
          title: "Profile Management",
          details: [
            "Complete user registration and profile detail management.",
          ],
        },
        {
          title: "Service Request Submission",
          details: [
            "Users can specify the type of service they want to discuss with an agent, such as mutual funds, SIP, or policies.",
          ],
        },
        {
          title: "Query Management",
          details: [
            "Mutual Fund (MF) Queries",
            "SIP Queries",
            "Policy-Related Queries",
            "Edit or update previously raised queries using a dedicated form.",
          ],
        },
        {
          title: "Push Notifications",
          details: [
            "Stay informed with real-time notifications for query updates, responses, or service-related alerts.",
          ],
        },
        {
          title: "Multilingual Support",
          details: [
            "Mobile app supports Hindi and English to cater to a diverse user base.",
          ],
        },
        // {
        //   title: "Profile Management",
        //   details: [
        //     "Edit and update user profile information, including preferences.",
        //   ],
        // },
        // {
        //   title: "Sort & Filter Functionality",
        //   details: [
        //     "Advanced filters based on price, location, property type, and more.",
        //     "Sorting options for an optimized search experience.",
        //   ],
        // },
      ],
      techStack: [
        {
          category: "Frontend",
          tools: [
            "HTML,CSS, JavaScript: For responsive and interactive web interface development.",
            "React: For building dynamic and reusable UI components.",
            "React Native: Developing a cross-platform mobile app for iOS and Android devices.",
          ],
        },
        {
          category: "Backend",
          tools: [
            " Node.js: High-performance backend logic for seamless user interactions.",
          ],
        },
        {
          category: "Database",
          tools: [
            "MongoDB: NoSQL database for efficiently managing user profiles, service requests, and queries.",
          ],
        },
        {
          category: "State Management",
          tools: [
            "Redux: Managing app state for smooth and scalable application performance.",
          ],
        },
        {
          category: "Authentication & Notifications",
          tools: [
            "Firebase: Handling user authentication (including OTP and biometrics) and sending push notifications.",
          ],
        },
        {
          category: "Localization",
          tools: [
            "Support for multiple languages (Hindi and English) to enhance accessibility and user experience",
          ],
        },
      ],
    },
  ];

  return (

    <div class='projectMain mt-5'>
      <div class='container'>
        <div class='project_banner text-center'>
          <h1>
            <span>
              Case Studies
              <img src={hightlightImg} alt="Description of the image" />
            </span>
            we do</h1>
        </div>

        {screenType == 'Home' ? <div class="row">
          {projBoxData.map((val) => (
            <div key={val.id} className="col-lg-6 col-md-6 col-sm-12">
              <div className="projBox mt-5">
                <img src={val.imgsrc} style={{ width: '100%' }} />
                <div class='projDesc'>
                  <p>{val.title}</p>
                  <span>{val.description}</span>
                </div>
              </div>
            </div>
          ))}
          <Link className='btn-primary-main caseSeeMore' to='/project'>See More</Link >
        </div> : <div className="accordion mb-5" id="accordionExample">
          {projBoxDataInner.map((val, index) => {
            const accordionId = `collapse${val.id}`; // Unique ID for each accordion
            const isDefaultOpen = index === 0; // Check if this is the first accordion

            return (
              <div key={val.id} className="accordion-item">
                <div className="projBox projBoxInner mt-5 p-md-4">
                  <div
                    className="projDesc projDescInner accordion-header"
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                  >
                    <button
                      className={`accordion-button ${isDefaultOpen ? '' : 'collapsed'}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${accordionId}`} // Dynamic target ID
                      aria-expanded={isDefaultOpen} // Default expanded for the first accordion
                      aria-controls={accordionId} // Dynamic aria-controls
                    >
                      <p>{val.title}</p>
                      <span>{val.description}</span>
                    </button>
                    <div
                      id={accordionId} // Dynamic ID
                      className={`accordion-collapse collapse ${isDefaultOpen ? 'show' : ''}`} // Show first accordion by default
                      data-bs-parent="#accordionExample" // Ensure only one accordion is open at a time
                    >
                      <div className="accordion-body">
                        <h5 className="my-4 keyFeatureUlTitle">{val.keyfeature}</h5>
                        <ul className="keyFeatureUl">
                          {val.features.map((feature, featureIndex) => (
                            <li key={featureIndex} className="mb-3">
                              <span className="d-flex mb-3">{feature.title}</span>
                              <ul className="keyDetailUl">
                                {feature.details.map((detail, detailIndex) => (
                                  <li key={detailIndex} className="mb-2">
                                    <span>{detail}</span>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                        <h5 className="my-4 keyFeatureUlTitle">{val.techfeature}</h5>
                        <ul className="keyFeatureUl">
                          {val.techStack.map((tech, techIndex) => (
                            <li key={techIndex} className="mb-3">
                              <span className="d-flex mb-3">{tech.category}</span>
                              <ul className="keyDetailUl">
                                {tech.tools.map((tool, toolIndex) => (
                                  <li key={toolIndex} className="mb-2">
                                    <span>{tool}</span>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        }
      </div>
    </div>
  )
}

export default Projects