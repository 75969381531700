import React from 'react';
import { Link } from 'react-router-dom';
import './WhyChooseUs.css';
import hightlightImg from '../assets/highlightText.png';
import aboutimg from '../assets/about-img.png';
import aboutbrand from '../assets/Grow-More- Wiserly.png';
import backgroundImage from '../assets/about-img.png';


const WhyChooseUs = ({ aboutData, columnClass }) => {

    return (
        <>
            <div className='aboutMain'>
                <div className='container'>
                    <div className="row">
                        {/* <div className='about_banner text-center my-5'>
                            <h1>
                                <span>
                                    Why Choose Us
                                    <img src={hightlightImg} alt="Description of the image" />
                                </span>
                            </h1>
                        </div> */}

                        {aboutData.map((val) => (
                            <div key={val.id} className={`mt-3 ${columnClass}`} >
                                <div className='ourVision'>
                                    <div className='chooseImgBox btn-primary-main'>
                                        <img src={val.chooseImg} />
                                    </div>
                                    <div className='chooseInner'>
                                        <p>{val.title}</p>
                                        <span>{val.description}</span>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div >
        </>
    )
}

export default WhyChooseUs