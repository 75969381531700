import React from 'react';
import footerLogo from '../assets/footer-logo.png';
import facebook from '../assets/facebook.png';
import instagram from '../assets/instagram.png';
import medium from '../assets/medium.png';
import linkedin from '../assets/linkedin.png';
import '../footer/Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div class='footerMain'>
            <div class='container-fluid'>
                <div class='container'>
                    <div class='row'>
                        <div class='col-lg-4 col-md-12'>
                            <div class='footerlogoblock'>
                                <img src={footerLogo} />
                            </div>
                        </div>
                        <div class='col-lg-4 col-md-12'>
                            <div className='policy'>
                                <Link to='/Privacypolicy'>
                                    Privacy Policy
                                </Link>
                                <Link to='/lifeours'>
                                    Life@GrowMore Wiserly
                                </Link>
                            </div>
                        </div>
                        <div class='col-lg-4 col-md-12'>
                            <ul>
                                <li>
                                    <Link to='https://www.facebook.com/profile.php?id=61550348587519' target="_blank">
                                        <img src={facebook} />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='https://www.instagram.com/growmorewiserly' target="_blank">
                                        <img src={instagram} />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='https://medium.com/@growmorewiserly' target="_blank">
                                        <img src={medium} />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='https://www.linkedin.com/company/growmore-wiserly/' target="_blank">
                                        <img src={linkedin} />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <ul className='terms-cond'>
                            {/* <li>
                                <Link>
                                    Terms & Conditions
                                </Link>
                            </li> */}
                            <li>
                                <div class='copyrightblock'>
                                    <span>© 2024 GMW. All rights reserved.</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer