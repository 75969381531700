import React from 'react';
import '../jobs/Jobs.css';
import hightlightImg from '../assets/highlightText.png';
import backgroundImage from '../assets/tech_bg.png';
import { Link } from 'react-router-dom';

const Jobs = () => {
    const jobDescriptions = [
        {
            id: 1,
            title: "UI/UX Design Intern",
            positionOverview: `We are looking for a creative and motivated UI/UX Design Intern to join our team. As an intern, you will work closely with our design team to create intuitive, engaging, and visually appealing user experiences for our digital platforms. This is an excellent opportunity to gain hands-on experience in a collaborative environment, learn from industry professionals, and build your portfolio.`,
            keyResponsibilities: [
                "Assist in creating wireframes, prototypes, and user interfaces for web and mobile applications.",
                "Conduct user research and usability testing to gather insights and feedback.",
                "Collaborate with developers, product managers, and other designers to ensure seamless implementation of design solutions.",
                "Support the design team in creating and maintaining design systems and style guides.",
                "Iterate designs based on feedback from stakeholders and end-users.",
                "Stay updated on the latest UI/UX trends, tools, and best practices."
            ],
            qualifications: [
                "Currently pursuing or recently completed a degree in UI/UX Design, Graphic Design, Human-Computer Interaction, or a related field.",
                "Proficiency in design tools such as Figma, Adobe XD, Sketch, or similar.",
                "Basic knowledge of HTML, CSS, and responsive design principles (preferred but not required).",
                "Strong portfolio showcasing design skills and problem-solving abilities.",
                "Excellent communication and collaboration skills.",
                "Attention to detail and a keen eye for aesthetics."
            ],
            whatWeOffer: [
                "Hands-on experience working on real-world projects.",
                "Mentorship and guidance from experienced UI/UX designers.",
                "A collaborative and creative work environment.",
                "Opportunities to contribute ideas and see them come to life."
            ]
        },
        {
            id: 2,
            title: "Social Media Marketing Intern",
            positionOverview: `We are seeking a dynamic and creative Social Media Marketing Intern to join our team. This internship provides an excellent opportunity to gain hands-on experience in managing and enhancing social media presence, creating engaging content, and driving brand awareness. You will work closely with the marketing team to execute and optimize our social media strategies.`,
            keyResponsibilities: [
                "Assist in planning, creating, and scheduling content for various social media platforms (e.g., Instagram, Facebook, Twitter, LinkedIn, Medium).",
                "Conduct research on current trends, hashtags, and audience preferences to optimize engagement.",
                "Monitor and respond to comments, messages, and interactions on social media channels.",
                "Collaborate with the design team to develop graphics, videos, and other creative assets.",
                "Track and analyze the performance of social media campaigns using analytics tools, providing actionable insights.",
                "Support the team in brainstorming and implementing innovative campaigns to grow followers and engagement.",
                "Stay updated on emerging social media trends, tools, and best practices."
            ],
            qualifications: [
                "Currently pursuing or recently completed a degree in Marketing or a computer science field.",
                "Strong understanding of major social media platforms and their features.",
                "Familiarity with tools like Canva, Figma, or other graphic creation software is a plus.",
                "Basic knowledge of social media analytics tools (e.g., Hootsuite, Buffer, Google Analytics).",
                "Highly organized with the ability to manage multiple tasks and meet deadlines."
            ],
            whatWeOffer: [
                "Hands-on experience in social media marketing and strategy development.",
                "Mentorship from experienced marketing professionals.",
                "A collaborative and energetic work environment.",
                "Opportunities to contribute ideas and bring them to life in real campaigns."
            ]
        },
        {
            id: 3,
            title: "Flutter App Developer Intern",
            positionOverview: `We are looking for a passionate and skilled Flutter App Developer Intern to
join our team. As an intern, you will work closely with our development team to
design, develop, and deploy mobile applications. This is an excellent
opportunity to gain hands-on experience in Flutter development, learn best
practices, and contribute to real-world projects.`,
            keyResponsibilities: [
                "Assist in developing and maintaining cross-platform mobile applications using Flutter.",
                "Collaborate with designers and product managers to translate UI/UX designs into functional applications.",
                "Write clean, maintainable, and efficient code.",
                "Test and debug applications to ensure optimal performance and functionality.",
                "Participate in code reviews and contribute to team discussions about technical solutions.",
                "Stay updated on the latest trends and updates in Flutter and mobile app development.",
                "Document development processes and technical specifications."
            ],
            qualifications: [
                "Currently pursuing or recently completed a degree in Computer. Science, Software Engineering, or a related field.",
                "Familiarity with Flutter, Dart, and mobile app development concepts.",
                "Basic understanding of RESTful APIs and integration.",
                "Knowledge of state management tools like Provider, Bloc, or Riverpod (preferred but not mandatory).",
                "Familiarity with Git and version control systems.",
                "Strong problem-solving skills and attention to detail.",
                "A portfolio or GitHub repository showcasing Flutter projects is a plus."
            ],
            whatWeOffer: [
                "Hands-on experience working on real-world Flutter applications.",
                "Mentorship and guidance from experienced developers.",
                "A collaborative and innovative work environment.",
                "Opportunities to learn and work with the latest tools and technologies in mobile app development."
            ]
        },
        {
            id: 4,
            title: "Node.js Developer Intern",
            positionOverview: `We are seeking a motivated and detail-oriented Node.js Developer Intern to
join our team. This internship provides an excellent opportunity to gain practical
experience in back-end development, working on scalable and efficient serverside applications. As an intern, you will collaborate with experienced developers
to build robust APIs and services.`,
            keyResponsibilities: [
                "Assist in designing, developing, and maintaining server-side logic and APIs using Node.js.",
                "Collaborate with front-end developers to integrate user-facing elements with server-side logic.",
                "Write reusable, testable, and efficient code following best practices.",
                "Work with databases (SQL and/or NoSQL) to store and retrieve data effectively.",
                "Debug and troubleshoot application issues to ensure high performance and responsiveness.",
                "Participate in code reviews and contribute to technical discussions.",
                "Stay updated on the latest trends and technologies in back-end development and Node.js."
            ],
            qualifications: [
                "Currently pursuing or recently completed a degree in Computer Science, Software Engineering, or a related field.",
                "Basic understanding of JavaScript and Node.js.",
                "Familiarity with frameworks like Express.js or Nest.js is a plus.",
                "Knowledge of RESTful APIs and JSON.",
                "Understanding of database technologies such as MongoDB, MySQL, or PostgreSQL.",
                "Familiarity with Git and version control systems.",
                "Problem-solving mindset and a willingness to learn new technologies.",
                "Any prior projects or GitHub repositories showcasing Node.js work is a bonus.",
            ],
            whatWeOffer: [
                "Hands-on experience in developing real-world applications.",
                "Mentorship and guidance from experienced software developers.",
                "A collaborative and supportive work environment.",
                "Opportunities to learn advanced concepts in back-end development."
            ]
        },
        {
            id: 5,
            title: "Business Analyst (4+ Years Experience)",
            positionOverview: `We are seeking an experienced and detail-oriented Business Analyst with over
4 years of expertise in analysing business processes, identifying opportunities
for improvement, and delivering effective solutions. The ideal candidate will
have a strong background in gathering and documenting requirements,
collaborating with cross-functional teams, and supporting the successful
delivery of projects.`,
            keyResponsibilities: [
                "As a Business Analyst, you will work closely with stakeholders to gather, analyze, and document business requirements. You will be responsible for developing comprehensive business cases, process models, and workflows to support proposed solutions. Additionally, you will analyse existing business processes to identify inefficiencies, risks, and improvement opportunities.",
                "In this role, you will act as a liaison between business stakeholders and technical teams to ensure clear communication and alignment. You will facilitate requirement workshops, meetings, and interviews with clients and internal teams to ensure all parties are on the same page.",
                "Furthermore, you will support project planning, monitoring, and delivery by ensuring alignment with business goals. You will be responsible for preparing and maintaining detailed documentation, including business requirements documents (BRD), functional specifications, and user stories.",
                "You will also conduct data analysis and visualisation to support decision-making and strategic planning. Additionally, you will assist in testing and quality assurance processes to validate solutions meet business needs.",
                "To excel in this role, it is important to stay updated on industry trends and advancements to recommend innovative solutions. Your ability to collaborate effectively with stakeholders and technical teams will be crucial in ensuring successful project outcomes."
            ],
            qualifications: [
                "Currently pursuing or recently completed a degree in UI/UX Design, Graphic Design, Human-Computer Interaction, or a related field.",
                "Proficiency in design tools such as Figma, Adobe XD, Sketch, or similar.",
                "Basic knowledge of HTML, CSS, and responsive design principles (preferred but not required).",
                "Strong portfolio showcasing design skills and problem-solving abilities.",
                "Excellent communication and collaboration skills.",
                "Attention to detail and a keen eye for aesthetics."
            ],
            whatWeOffer: [
                "A dynamic work environment with opportunities to work on challenging projects.",
                "Collaboration with a talented and supportive team.",
                "Professional development and training opportunities.",
                "Competitive compensation and benefits package."
            ]
        },
        // Add the other job descriptions in the same format...
    ];

    return (
        <div class='jobMain mt-5'>
            <div class='container'>
                <div class='job_banner text-center'>
                    <h1>
                        <span>
                            Job Opportunities
                            <img src={hightlightImg} alt="Description of the image" />
                        </span>
                    </h1>
                </div>

                <div className="accordion mb-5" id="accordionExample">
                    {jobDescriptions.map((job, index) => {
                        const accordionId = `collapse${job.id}`; // Unique ID for each accordion
                        const isDefaultOpen = index === 0; // Open the first accordion by default

                        return (
                            <div key={job.id} className="accordion-item">
                                <div className="jobBox jobBoxInner mt-5 p-md-4">
                                    <div
                                        className="jobDesc jobDescInner accordion-header"
                                        style={{ backgroundImage: `url(${backgroundImage})` }}
                                    >
                                        <button
                                            className={`accordion-button ${isDefaultOpen ? '' : 'collapsed'}`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#${accordionId}`}
                                            aria-expanded={isDefaultOpen}
                                            aria-controls={accordionId}
                                        >
                                            <p>{job.title}</p>
                                        </button>
                                    </div>
                                    <div
                                        id={accordionId}
                                        className={`accordion-collapse collapse ${isDefaultOpen ? 'show' : ''}`}
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <h4 className="my-4">Position Overview</h4>
                                            <p>{job.positionOverview}</p>

                                            <h4 className="my-4">Key Responsibilities</h4>
                                            <ul className="keyFeatureUl">
                                                {job.keyResponsibilities.map((responsibility, idx) => (
                                                    <li key={idx} className="mb-3">
                                                        {responsibility}
                                                    </li>
                                                ))}
                                            </ul>

                                            <h4 className="my-4">Qualifications</h4>
                                            <ul className="keyFeatureUl">
                                                {job.qualifications.map((qualification, idx) => (
                                                    <li key={idx} className="mb-3">
                                                        {qualification}
                                                    </li>
                                                ))}
                                            </ul>

                                            <h4 className="my-4">What We Offer</h4>
                                            <ul className="keyFeatureUl">
                                                {job.whatWeOffer.map((offer, idx) => (
                                                    <li key={idx} className="mb-3">
                                                        {offer}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default Jobs