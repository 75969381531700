import React, { useState } from 'react';
import '../connect/Connect.css';
import hightlightImg from '../assets/highlightText.png';
import backgroundImage from '../assets/blog-bg.png';

function Connect({ ConnectRef }) {
    const branchData =
        [
            {
                id: 1,
                Location: 'Jaipur, Rajasthan 302021'
            },
            {
                id: 2,
                Email: 'info@growmorewiserly.com'
            },
            {
                id: 3,
                ContactNo: '9414283688, 8949859282'
            },
            {
                id: 4,
                OpenHours: 'Mon-Fri : 10AM - 7PM'
            },
        ]

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        contact: '',
        textarea: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = validateForm(formData);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            // Construct the mailto link
            const mailtoLink = `mailto:info@growmorewiserly.com?subject=Form%20Submission&body=${encodeURIComponent(
                `Username: ${formData.username}\nEmail: ${formData.email}\nContact: ${formData.contact}\nMessage: ${formData.textarea}`
            )}`;

            // Redirect to mailto link
            window.location.href = mailtoLink;
            console.log('Form submitted successfully!');
        } else {
            console.log('Form submission failed due to validation errors.');
        }
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.username.trim()) {
            errors.username = 'Username is required';
        } else if (data.username.length < 4) {
            errors.username = 'Username must be at least 4 characters long';
        }

        if (!data.email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Email is invalid';
        }

        if (!data.contact) {
            errors.contact = 'contact is required';
        } else if (data.contact.length < 10) {
            errors.contact = 'contact must be at least 10 characters long';
        }

        if (!data.textarea) {
            errors.textarea = 'textarea is required';
        } else if (data.textarea.length < 15) {
            errors.textarea = 'textarea must be at least 15 characters long';
        }
        return errors;
    };

    return (
        <div ref={ConnectRef} className='connectMain' style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='about_banner text-center my-5'>
                            <h1>
                                <span>
                                    Get In Touch With US
                                    <img src={hightlightImg} alt="Description of the image" />
                                </span>
                            </h1>
                        </div>
                        <div className='row'>
                            {branchData.map((val) => (
                                <div key={val.id} className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="ourOffices">
                                        {val.Location && (
                                            <>
                                                <p>Location: </p>
                                                <span>{val.Location}</span>
                                            </>
                                        )}
                                        {val.Email && (
                                            <>
                                                <p>Email: </p>
                                                <span>{val.Email}</span>
                                            </>
                                        )}
                                        {val.ContactNo && (
                                            <>
                                                <p>Contact No: </p>
                                                <span>{val.ContactNo}</span>
                                            </>
                                        )}
                                        {val.OpenHours && (
                                            <>
                                                <p>Open Hours: </p>
                                                <span>{val.OpenHours}</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>

                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='about_banner text-center my-5'>
                            <h1>
                                <span>
                                    Need Some Help
                                    <img src={hightlightImg} alt="Description of the image" />
                                </span>
                            </h1>
                        </div>
                        <div className="form-container">
                            <div class='formHeader'>
                                <h3 className='text-center mb-4'>Let’s Connect</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label className="form-label">
                                        Name:
                                    </label>
                                    <input
                                        className="form-input"
                                        type="text"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                    />
                                    {errors.username && (
                                        <span className="error-message">
                                            {errors.username}
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <label className="form-label">
                                        Email:
                                    </label>
                                    <input
                                        className="form-input"
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && (
                                        <span className="error-message">
                                            {errors.email}
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <label className="form-label">
                                        Contact:
                                    </label>
                                    <input
                                        className="form-input"
                                        type="text"
                                        name="contact"
                                        value={formData.contact}
                                        onChange={handleChange}
                                    />
                                    {errors.contact && (
                                        <span className="error-message">
                                            {errors.contact}
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <label className="form-label">
                                        Message:
                                    </label>
                                    <textarea
                                        className="form-input"
                                        type="text"
                                        name="textarea"
                                        value={formData.textarea}
                                        onChange={handleChange}
                                    />
                                    {errors.textarea && (
                                        <span className="error-message">
                                            {errors.textarea}
                                        </span>
                                    )}
                                </div>
                                <button className="submit-button btn-primary-main mt-2" type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Connect;