import React, { useRef, useEffect } from 'react';
import '../home/Home.css';
import anime from "animejs";
import backgroundImage from '../assets/background.png';
import Services from '../services/Services';
import Technology from '../technology/Technology';
import Projects from '../projects/Projects';
import Achivement from '../achivement/Achivement';
import Testimonial from '../testimonials/Testimonial';
import About from '../about/About';
import Blogs from '../blogs/Blogs';
import WeWorkWith from '../weWorkWith/WeWorkWith';
import Connect from '../connect/Connect';
import HeroSlider from './innerComponents/HeroSlider';
import WaveAnimation from './innerComponents/WaveAnimation';

const Home = ({ setScrollToBottom }) => {
  const ConnectRef = useRef(null);
  const scrollToConnect = () => {
    ConnectRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (setScrollToBottom) {
      setScrollToBottom.current = scrollToBottom;
    }

    const textWrapper = document.querySelector(".ml3");
    if (textWrapper) {
      // Wrap each letter with a span
      textWrapper.innerHTML = textWrapper.textContent.replace(
        /\S/g,
        "<span class='letter'>$&</span>"
      );

      // Anime.js animation
      anime.timeline({ loop: true })
        .add({
          targets: ".ml3 .letter",
          opacity: [0, 1],
          easing: "easeInOutQuad",
          duration: 500,
          delay: (el, i) => 50 * (i + 1),
        })
        .add({
          targets: ".ml3",
          opacity: 0,
          duration: 10,
          easing: "easeOutExpo",
          delay: 200,
        });
    }
  }, [setScrollToBottom]);

  return (
    <>
      <div className="dynamic-background" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div class="container">
          <div class="row">
            <div class="col-lg-6 ">
              <div className='bannerLeft'>
                <h1>Driving Success through Innovative Solutions</h1>
                <p className='ml3'>With our expertise in app development, UI/UX design, and strategic marketing, we help drive your business towards unprecedented success</p>
                <button type="button" class="btn btn-primary-main me-auto px-4" onClick={scrollToConnect}>Let's Talk</button>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="text-center">
                <HeroSlider />
              </div>
            </div>
          </div>
        </div>
        <WaveAnimation />
      </div>
      <Services screenType='Home' />
      <section className='parallex pb-5'>
        <Technology screenType='Home' />
      </section>
      {/* <Technology screenType='Home' /> */}
      <Projects screenType='Home' />

      <Achivement />
      <Testimonial />
      <About screentype='Home' />
      <section className='parallex2 pb-5'>
        <WeWorkWith />
      </section>
      {/* <WeWorkWith /> */}
      <Blogs />
      <Connect ConnectRef={ConnectRef} />
      {/* <Footer /> */}
    </>
  )
}

export default Home