import React from 'react';
import { Link } from 'react-router-dom';
import '../about/about.css';
import hightlightImg from '../assets/highlightText.png';
import aboutimg from '../assets/about-img.png';
import aboutbrand from '../assets/Grow-More- Wiserly.png';
import backgroundImage from '../assets/about-img.png';
import WhyChooseUs from '../innerComponents/WhyChooseUs';


const About = ({ screentype }) => {
  const chooseData = [
    {
      id: 1,
      title: 'Tailored IT Solutions for Your Business Needs',
      description: 'Our team specializes in crafting innovative IT solutions tailored to meet your unique business objectives. Whether you require process optimization, enhanced security measures, or infrastructure scaling, we provide strategic solutions that address your specific challenges and opportunities.',
      chooseImg: require('../assets/strategy1.png')
    },
    {
      id: 2,
      title: 'Expert Team with Diverse Expertise',
      description: 'Comprised of highly skilled professionals with expertise in a wide array of technologies, our team brings a wealth of knowledge to the table. From cloud computing and AI to cybersecurity and data analytics, we possess in-depth understanding across various IT domains to ensure exceptional results for every project.',
      chooseImg: require('../assets/award2.png')
    },
    {
      id: 3,
      title: 'Utilizing Cutting-Edge Technology and Tools',
      description: 'We harness the latest technology and industry best practices to provide you with advanced, scalable solutions. By staying abreast of technological advancements, we guarantee that our clients benefit from the most efficient, secure, and future-proof IT systems available.',
      chooseImg: require('../assets/tools4.png')
    },
    {
      id: 4,
      title: 'Achievements & Proven Track Record of Success',
      description: 'With a wealth of experience and a history of successful projects, we have established a reputation for delivering high-quality IT services that yield measurable results. Our portfolio showcases our ability to tackle complex challenges and deliver value across a multitude of industries.',
      chooseImg: require('../assets/goal5.png')
    },
  ];

  const vision =
    [
      {
        id: 1,
        title: ['Our', 'Mission'],
        description: 'Our mission is simple yet powerful - to revolutionize the way businesses operate and thrive in an increasingly digital world. With cutting-edge technology and innovative solutions, we are dedicated to providing top-notch IT services that elevate your business to new heights. Trust us to be your partner in success, driving efficiency, productivity, and growth. Join us on our mission to elevate your business and transform the future of IT',
        visionImg: require('../assets/mission.png')
      },
      {
        id: 2,
        title: ['Our', 'Vision'],
        description: `Our vision serves as our guiding compass, helping us navigate towards our goals and objectives. It provides a sense of direction, purpose, and motivation for both our employees and stakeholders. A strong vision statement communicates the company's values, aspirations, and long-term strategic plans. It outlines what sets us apart from competitors and how we plan to make a positive impact on the industry.`,
        visionImg: require('../assets/visionary.png')
      },
      {
        id: 3,
        title: ['Core', 'Values'],
        description: 'At our IT Company, we prioritize three core values above all else: innovation, integrity, and excellence. Our team consistently pushes the boundaries of technology to create cutting-edge solutions for our clients, all while upholding the highest ethical standards. We strive for excellence in everything we do, delivering top-notch products and services that exceed expectations.',
        visionImg: require('../assets/value.png')
      },
    ]
  return (
    <>
      {
        screentype == 'Home' ?
          <div className='aboutMain my-5'>
            <div className='container'>
              <div className='about_banner text-center'>
                <h1>
                  <span>
                    About Us
                    <img src={hightlightImg} alt="Description of the image" />
                  </span>
                </h1>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="aboutBoxMain mt-5">
                    <img src={aboutimg} style={{ width: '100%' }} />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className='mt-5'>
                    <p style={{ lineHeight: '2rem' }}>GMW (GrowMore Wiserly) is an IT-based software company specializing in
                      mobile app development, UX/UI design, web development, testing, and IT
                      consultation. We offer a variety of services and solutions to meet the unique
                      needs of our clients. With our expertise and experience, we can help you
                      make the most of your technology and get the most out of your business.
                      Apart from this, our team specializes in mobile app development, serving
                      various industries.</p>
                    <p style={{ lineHeight: '2rem' }}>What sets us apart from the rest is our commitment to writing secure,
                      scalable, and maintainable high-quality code. We strive to cultivate long-term
                      professional relationships and deliver outstanding products that utilize the
                      latest technologies. For us, perfection is not just a word, but a way of life.</p>

                    <Link to="/about" className="btn btn-primary-main me-auto px-4" style={{ textDecoration: "none" }}>
                      Know More
                    </Link>

                  </div>
                </div>
              </div>
            </div>
            <div className='aboutSepBrand'>
              <img src={aboutbrand} />
            </div>
          </div> : <div className='aboutMain'>
            <div className='container'>
              <div className='about_banner text-center'>
                <h1>
                  <span>
                    About Us
                    <img src={hightlightImg} alt="Description of the image" />
                  </span>
                </h1>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="aboutBox mt-5" style={{ backgroundImage: `url(${backgroundImage})` }}>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className='mt-3'>
                    <p style={{ lineHeight: '2rem' }}>GMW (GrowMore Wiserly) is an IT-based software company specializing in
                      mobile app development, UX/UI design, web development, testing, and IT
                      consultation. We offer a variety of services and solutions to meet the unique
                      needs of our clients. With our expertise and experience, we can help you
                      make the most of your technology and get the most out of your business.
                      Apart from this, our team specializes in mobile app development, serving
                      various industries.</p>
                    <p style={{ lineHeight: '2rem' }}>What sets us apart from the rest is our commitment to writing secure,
                      scalable, and maintainable high-quality code. We strive to cultivate long-term
                      professional relationships and deliver outstanding products that utilize the
                      latest technologies. For us, perfection is not just a word, but a way of life.</p>
                  </div>
                </div>

                <div className='about_banner text-center mt-5 mb-5'>
                  <h1>
                    <span>
                      Our Vision
                      <img src={hightlightImg} alt="Description of the image" />
                    </span>
                  </h1>
                </div>

                {vision.map((val) => (
                  <div key={val.id} className='col-lg-4 col-md-6 col-sm-12'>
                    <div className='visionBlock mt-5'>
                      <img src={val.visionImg} className='btn-primary-main' />
                      <div className='visionTitle'>
                        <p>
                          {val.title[0]}
                          <span> {val.title[1]}</span>
                        </p>
                      </div>
                      <div className='visionDesc'>
                        <span>{val.description}</span>
                      </div>
                    </div>
                  </div>
                ))}

                <div className='about_banner text-center mt-5 mb-5'>
                  <h1>
                    <span>
                      Why Choose Us
                      <img src={hightlightImg} alt="Description of the image" />
                    </span>
                  </h1>
                </div>

                <WhyChooseUs aboutData={chooseData} columnClass='col-lg-3 col-md-6 col-sm-12' />

              </div>
            </div>
          </div >
      }
    </>
  )
}

export default About