import React, { useState, useEffect } from "react";
import "./CircleCursor.css"; // Import the CSS styles

const CursorTrail = () => {
  const [trail, setTrail] = useState([]);
  const trailLength = 20;

  useEffect(() => {
    const handleMouseMove = (e) => {
      const newCursor = {
        x: e.pageX,
        y: e.pageY,
        id: Date.now() + Math.random(), // Unique ID for each cursor element
      };

      setTrail((prevTrail) => {
        const updatedTrail = [...prevTrail, newCursor];
        if (updatedTrail.length > trailLength) {
          updatedTrail.shift(); // Remove the oldest cursor to maintain the trail length
        }
        return updatedTrail;
      });
    };

    // Add event listener for mouse movement
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      {trail.map((cursor) => (
        <div
          key={cursor.id}
          className="cursor"
          style={{
            left: `${cursor.x}px`,
            top: `${cursor.y}px`,
          }}
        ></div>
      ))}
    </>
  );
};

export default CursorTrail;
