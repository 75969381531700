import React from 'react';
import '../privacyPolicy/Privacypolicy.css';

const Privacypolicy = () => {
    return (
        <div className='privacyContent my-5'>
            <div className='container'>
                <h4>Privacy Policy Page</h4>
                <p>This page informs you of our policies regarding the collection, use, and
                    disclosure of personal data when you use our Service and the choices you have
                    associated with that data.
                    We use your data to provide and improve the Service. By using the Service, you
                    agree to the collection and use of information in accordance with this policy.</p>
                <h5> Information Collection And Use</h5>
                <p>We collect several different types of information for various purposes to
                    provide and improve our Service to you.
                    Types of Data Collected
                    Personal Data
                    While using our Service, we may ask you to provide us with certain personally
                    identifiable information that can be used to contact or identify you ("Personal
                    Data"). Personally identifiable information may include, but is not limited to:-</p>
                <ul>
                    <li>Email address</li>
                    <li>First name</li>
                    <li>last name</li>
                    <li>Phone number</li>
                </ul>
                <h5> Use of Data</h5>
                <p>GrowMore Wiserly uses the collected data for various purposes:-</p>
                <ul>
                    <li>To provide and maintain the Service</li>
                    <li>To notify you about changes to our Service</li>
                    <li>To allow you to participate in interactive features of our Service when you
                        choose to do so</li>
                    <li>To provide customer care and support</li>
                    <li>To provide analysis or valuable information so that we can improve the Service</li>
                    <li>To detect, prevent and address technical issues</li>
                </ul>
                <h5>Disclosure Of Data</h5>
                <p>Legal Requirements GrowMore Wiserly may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                <ul>
                    <li>To comply with a legal obligation</li>
                    <li>To protect and defend the rights or property of GrowMore Wiserly</li>
                    <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li>To protect the personal safety of users of the Service or the public</li>
                    <li>To protect against legal liability</li>
                </ul>
                <h5>Security Of Data</h5>
                <p>The security of your data is important to us, but remember that no method of
                    transmission over the Internet, or method of electronic storage is 100% secure.
                    While we strive to use commercially acceptable means to protect your Personal
                    Data, we cannot guarantee its absolute security.</p>
                <p>Changes To This Privacy Policy
                    We may update our Privacy Policy from time to time. We will notify you of any
                    changes by posting the new Privacy Policy on this page.
                    We will let you know via email and/or a prominent notice on our Service, prior to
                    the change becoming effective and update the "effective date" at the top of
                    this Privacy Policy.
                    You are advised to review this Privacy Policy periodically for any changes.
                    Changes to this Privacy Policy are effective when they are posted on this page.</p>
                <h5>Contact Us</h5>
                <p>If you have any questions about this Privacy Policy, please contact us:</p>
                <p>By email: info@growmorewiserly.com</p>
                <p>Last updated: 2024</p>
            </div>
        </div>
    )
}

export default Privacypolicy